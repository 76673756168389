.dashboardHero {
    text-align: center;
    color: #00adef;
    margin-top: 10px;
    font-size: 30px;
}

.menuStyle {
    color: white;
    font-size: 16px;
    background-color: #2a3439;
    border-radius: 0 10px 10px 0;
}

.frontPage {
    margin: 0 0 0 15px
}
.supportLogo {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto; 
    padding: 10px;
    margin: 10px auto;
    text-align: center;
    
}
.supportPageHeader {
    font-size: 35px;
    font-weight: bold;
}

.supportH2 {
    font-size: 25px;
    font-weight: bold;
}
.supportH3 {
    font-size: 16px;
}
.link {
    color: #0066ed;
    text-decoration: none;
}
.link:hover {
    text-decoration: underline;
}
.subscription {
    margin-left: 5%;
    font-weight: bold;
}
.subscriptionM {
    margin-left: 5%;
    font-weight: bold;
    text-decoration: underline;
}

.Sbutton {
    background-color:#337dfa;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
    transition-duration: 0.4s;
    outline-style: none;
}

.Sbutton:hover {
    background-color: #10449e;
    color: white;
}

.Sbutton:focus {
    outline: 0 !important;
}

.contactContainer {
    background-color:#337dfa;
    color: snow;
    border-radius: 5px;
    padding: 30px;
    margin: 20px;
}

.Input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: black;
  }